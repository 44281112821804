/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'




export type IRequest = CamelCasedPropertiesDeep<{
  productId: number,
  fileId: number
}>

export const apiFunction = ({ productId,fileId }: IRequest) =>
  api.get(`/pim/products/wb/product/${productId}/photos/${fileId}`,{ responseType: 'blob' })


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ productId,fileId }: IRequest) => ['get', `/pim/products/wb/product/${productId}/photos/${fileId}`, { productId,fileId }] as const

export const useDownloadPhoto = (queryParams: IRequest, options?: any) =>
  useQuery<
    AxiosResponse,
    errorType,
    AxiosResponse,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

