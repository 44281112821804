import styled from 'styled-components'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

import { StyledTable } from '../../../ui/styled'

export const StyledTableBox = styled(Box)`
  min-height: 250px;
  position: relative;
  border: none;
  box-shadow: none;

  .MuiTableCell-body {
    height: 108px;
  }
  
  .MuiTableCell-root {
    :last-child {
      position: sticky;
      z-index: 1;
      right: 0;
    }
  }
  
  .MuiToolbar-root {
      display: none;
  }

  .SearchPanelInput-root {
    margin-left: 0;
    width: 220px;
    display: flex;
    flex-direction: row-reverse;

    div:first-child {
      display: flex;
    }
  }
`

export const StyledLoader = styled(CircularProgress)`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  z-index: 1;
`

export const StyledTableWrapper = styled(StyledTable)`
  .table-row {
    >div {
      div:first-child {
        display: flex;
      }
      
      :first-child {
        width: 100%;
        border-right: 1px solid #E0E0E0;
      }
    }
  }
`