/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { BodyTemplateDeleteReviewTemplatesModel, ResponseModelId } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ResponseModelId>

export type IRequest = CamelCasedPropertiesDeep<{
  modelId: number,
  data: BodyTemplateDeleteReviewTemplatesModel
}>

export const apiFunction = ({ modelId,data }: IRequest) =>
  api.delete<ResponseType>(`/review/models/${modelId}`,{ data: data}).then(res => res.data)


export const useDeleteReviewTemplatesModel = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

