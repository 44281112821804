import React from 'react'
import { useTableFiltersPresets } from 'shared/lib/hooks/useTableFiltersPresets'
import { StyledChips } from 'shared/ui/styled/StyledChips'
import { useQueryParams } from 'shared/lib/hooks/useQueryParams'
import { dialogModel } from 'shared/ui/components/dialog'

import { SavePresetModal } from './modal/SavePresetModal'

export const PresetsSaveButton = ({ filtersPresetsKey }: { filtersPresetsKey: string }) => {
  const { searchObj } = useQueryParams({ parseBooleans: true, arrayFormat: 'bracket' })
  const { editFiltersPresets, filtersPresets } = useTableFiltersPresets({ filtersPresetsKey })

  const { endValue, startValue, timelineEnd, timelineStart, range, tab, ...rest } = searchObj

  const handleSaveChanges = () => {
    dialogModel.openDialog({
      component: ({ close, accept }) => (
        <SavePresetModal
          close={close}
          accept={accept}
          filtersPresets={filtersPresets}
        />
      ),
      onAccept: (presetName) => {
        editFiltersPresets({ ...filtersPresets, [presetName.trim()]: rest })
      }
    })
  }

  const renderSaveButton = !JSON.stringify(filtersPresets).includes(JSON.stringify(rest))

  if (renderSaveButton) return (
    <StyledChips size="small" label="Сохранить" onClick={handleSaveChanges}/>
  )

  return null
}