/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CollectionList } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CollectionList>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Search String
   * Подстрока поиска
   */
    search_string: string,
  /**
   * Companymarketplace Id
   * Идентификатор кабинета
   */
    companymarketplace_id: number,
  /**
   * Timeout
   * Гарантированное время отклика в миллисекундах
   * @default 5000
   */
    timeout?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/wbproxy/collections`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/wbproxy/collections`, { query }] as const

export const usegetCollections = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

