/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CeleryTaskStart, Review } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CeleryTaskStart>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Review Type
   * Тип review
   */
    review_type: "new" | "part" | "all",
  /**
   * Companymarketplace Id
   * ID кабинета
   */
    companymarketplace_id?: number,
  /**
   * Part Bulk Size
   * Кол-во
   */
    part_bulk_size: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.patch<ResponseType>(`/review/celery_settings/task/scraping/questions`, {}, { params: query}).then(res => res.data)


export const useScrapingQuestions = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

