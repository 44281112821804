import { useHistory, useParams } from 'react-router-dom'
import arrayMutators from 'final-form-arrays'
import { Box } from '@mui/material'
import { pimApi } from 'shared/api'
import { Form } from 'shared/ui/components'
import { PRODUCT_LIST } from 'shared/config/routes'
import { WBProductContextProvider } from 'entities/pim/product/model/wb/useWBProductContext'
import { dialogModel } from 'shared/ui/components/dialog'
import { DeleteConfirmModal } from 'entities/pim/goodsComponents'
import { snackActions } from 'shared/lib/react/snackbar'
import { isSameValues } from 'shared/lib/checkers'
import { AnyObject } from 'final-form'
import { useQueryClient } from 'react-query'
import useValidationSchema from 'shared/lib/hooks/useYupValidation'
import { SSEProvider } from 'react-hooks-sse'
import { WBVariation } from 'shared/api/pim/products/wb'
import { useMemo } from 'react'
import { wbProductStreamSource } from 'shared/lib/EventSource/SourcesList'

import { ErrorText } from './styled'
import { ProductInfoTabs } from './ProductInfoTabs'
import { Nomenclatures } from './Nomenclatures'
import { SaveForm } from './SaveForm'

import { serializeEditSubmitFormValuesToEditWBCard, validationSchemaAll } from '../lib'


interface WBProductEditProps {
  productId: number
  commonProduct: pimApi.products.common.Product
}

interface ISubmittingForm extends pimApi.products.wb.WBNomenclature {
  action: string
}

function getNomenclatureArchivingStatus(variations?: WBVariation[]) {
  const archivedVariations = variations?.filter(
    (variation) => variation?.inArchive
  )
  return archivedVariations?.length === variations?.length
}

export const WBProductEdit = ({ productId, commonProduct }: WBProductEditProps) => {
  const history = useHistory()

  const { id } = useParams<{ id: string }>()
  const wbProductQuery = pimApi.products.wb.useFetchWBProductQuery(productId)
  const queryClient = useQueryClient()
  const {
    mutate: updateProductMutate,
    isLoading: isUpdateProductLoading
  } = pimApi.products.wb.useUpdateProductWBMutation(productId)
  const { mutate: syncWB, isLoading: isSyncWBLoading } = pimApi.products.wb.useSyncProductWithWildberriesMutation()
  const { mutate: deleteProduct, isLoading: isDeleteProductLoading } = pimApi.products.wb.useDeleteProductMutation()

  const initialValues = useMemo(() => {
    if (wbProductQuery.data) {
      return wbProductQuery.data
    }
    return undefined
  }, [wbProductQuery.isLoading, isUpdateProductLoading, id])

  const getValidationSchema = () => validationSchemaAll

  const validate = useValidationSchema(getValidationSchema())

  const isVariationsArchived = getNomenclatureArchivingStatus(
    wbProductQuery?.data?.variations
  )

  const updateProduct = (formValues: pimApi.products.wb.WBNomenclature) => {
    if (isSameValues(formValues, initialValues)) return
    const editWBCardPayload = serializeEditSubmitFormValuesToEditWBCard(formValues)
    updateProductMutate(editWBCardPayload, {
      onSuccess: (response) => {
        queryClient.setQueryData(pimApi.products.wb.getWBProductQueryKey(parseInt(id, 10)), response?.data)
        snackActions.info('Сохранено!')
      }
    })
  }
  const handleSave = (formValues: pimApi.products.wb.WBNomenclature) => {
    if (isVariationsArchived) {
      return
    }
    updateProduct(formValues)
  }

  const handleSync = () => {
    syncWB({ productId }, {
      onSuccess(resp) {
        queryClient.setQueryData(pimApi.products.wb.getWBProductQueryKey(parseInt(id, 10)), resp.data)
      },
    })
  }

  const handleFormSubmit = (formValues: ISubmittingForm) => {
    const { action, ...rFormValues } = formValues
    handleSave(rFormValues)
    if (action === 'sync') {
      handleSync()
    }
  }

  const handleDelete = () => {
    dialogModel.openDialog({
      component: DeleteConfirmModal,
      onAccept: () => {
        deleteProduct(
          {
            productId,
            id: wbProductQuery?.data?.id,
            versionNo: wbProductQuery?.data?.versionNo,
          },
          {
            onSuccess: () => {
              history.push(PRODUCT_LIST)
              snackActions.info('Продукт успешно удален!')
            },
          }
        )
      },
    })
  }

  const getValidationErrorText = (errors: AnyObject|undefined): string => {
    if (errors !== undefined) {
      let error_text = Object.values(errors)[0]
      if (typeof error_text === 'string') {
        return error_text
      }
      [error_text] = Object.values(error_text)
      if (typeof error_text === 'string') {
        return error_text
      }
    }
    return 'Ошибки в обязательных полях'
  }

  if (!wbProductQuery.data) return null
  return (
    <SSEProvider key={id} source={() => wbProductStreamSource({ id })}>
      <WBProductContextProvider wbProductQuery={wbProductQuery}>
        <Form
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validate={validate}
          mutators={{ ...arrayMutators }}
          subscription={{ initialValues: true, hasValidationErrors: true, submitFailed: true, errors: true }}
          render={({ handleSubmit, hasValidationErrors, submitFailed, errors }) => (
            <form onSubmit={handleSubmit} style={{ maxWidth: 'inherit' }}>
              <Nomenclatures isVariationsArchived={isVariationsArchived} />
              {hasValidationErrors && submitFailed && (
                <Box mt={2} display="flex" flexWrap="wrap">
                  <Box display="flex" flexBasis="100%">
                    <ErrorText>{getValidationErrorText(errors)}</ErrorText>
                  </Box>
                </Box>
              )}
              <Box mt={3}>
                <ProductInfoTabs
                  key="ProductInfoTabs"
                  commonProduct={commonProduct}
                  isArchived={isVariationsArchived}
                />
              </Box>
              <SaveForm
                isSyncWBLoading={isSyncWBLoading}
                isDeleteProductLoading={isDeleteProductLoading}
                handleDelete={handleDelete}
                isUpdateProductLoading={isUpdateProductLoading}
                isVariationsArchived={isVariationsArchived}
                wbProductQuery={wbProductQuery}
              />
            </form>
          )}
        />
      </WBProductContextProvider>
    </SSEProvider>
  )
}
