/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { MethodParam } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<boolean>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** Тип токена на Wildberries */
    token_type: "content" | "statistics" | "prices",
  /**
   * Операция над токеном
   * @default "check"
   */
    method?: "check",

},
  data: MethodParam
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.patch<ResponseType>(`/wbproxy/token`,data, { params: query }).then(res => res.data)


export const useperformTokenOperation = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

