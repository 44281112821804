/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ReviewBot } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReviewBot[]>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /** ID продукта */
    product_id?: number,
  /** ID модели */
    templatemodel_id?: number,
  /**
   * ID типа
   * 1 - Отзывы 2 - Вопросы
   */
    reviewtype_id?: number,
  /** ID кабинета */
    companymarketplace_id?: number,
  /**
   * Номер страницы p > 0
   * @min 0
   * @default 0
   */
    page?: number,
  /**
   * Количество элементов на странице
   * @min 0
   * @max 1000
   * @default 100
   */
    page_size?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/review/bot`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/review/bot`, { query }] as const

export const useGetreviewsbot = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

