/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { Locale } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Поиск по строке
   * @default ""
   */
    textsearch?: string,
  /** ID кабинета */
    companymarketplace_id: number,
  /**
   * Язык полей ответа
   * @default "ru"
   */
    locale?: Locale,
  /**
   * Лимит записей макс 1000
   * @default 1000
   */
    limit?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/wbproxy/basket/card`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/wbproxy/basket/card`, { query }] as const

export const usegetBasket = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

