/* eslint-disable */
import { api } from 'shared/api/base'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { NomenclatureMethod, PatchNomenclature } from '../Api'



export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Imtid
   * Идентификатор карточки на Wildberries
   */
    imtid?: number,
  /**
   * Nmid
   * Идентификатор добавляемой номенклатуры на Wildberries
   */
    nmid?: number,
  /** Операция над номенклатурой */
    method: NomenclatureMethod,
  /**
   * Companymarketplace Id
   * Идентификатор кабинета
   */
    companymarketplace_id: number,
  /**
   * Timeout
   * Гарантированное время отклика в миллисекундах
   * @default 5000
   */
    timeout?: number,

},
  data: PatchNomenclature
}>

export const apiFunction = ({ query,data }: IRequest) =>
  api.patch(`/wbproxy/nomenclature`,data, { params: query }).then(res => res.data)


export const usemoveNomenclature = () =>
  useMutation<
    AxiosResponse,
    errorType,
		IRequest
  >(apiFunction)

