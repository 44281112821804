/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /** customer_code */
    customer_code: string,
  /** review_code */
    review_code: string,
  /** review_type */
    review_type_name: string,
  /** companymarketplace_id */
    companymarketplace_id: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/wbproxy/review`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/wbproxy/review`, { query }] as const

export const usegetReview = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

