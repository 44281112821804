/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CeleryTaskStart } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CeleryTaskStart>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Marketplace Id
   * ID маркетплейса
   */
    marketplace_id: number,
  /**
   * Companymarketplace Id
   * ID кабинета
   */
    companymarketplace_id?: number,
  /**
   * Count
   * Кол-во
   */
    count: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.patch<ResponseType>(`/review/celery_settings/task/bot/answer`, {}, { params: query}).then(res => res.data)


export const useBotAnswerReview = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

