import React, { useRef } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import ClearIcon from '@mui/icons-material/Clear'


interface FileParameterValue {
  fileName: string;
  file: string;
}

interface FileSelectFieldProps {
  input: FieldRenderProps<FileParameterValue>;
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export const FileSelectField = ({ input } : FileSelectFieldProps) => {

  console.log(input)
  const inputRef = useRef<HTMLInputElement>(null)
  const onSelectFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0] !== undefined) {
      const file: File = event.target.files[0]
      input.onChange({
        fileName: file.name,
        file: await toBase64(file)
      })
    }
  }
  const onClearFile = () => {
    input.onChange('')
    if (inputRef.current !== null) {
      inputRef.current.value=''
    }
  }

  return (
    <>
      <label htmlFor={`file-upload-${input.name}`}
        style={ {
          width: inputRef?.current?.value ? '' : '64px',
          display: 'flex',
          alignItems: 'center'
        } }>
        <input id={`file-upload-${input.name}`} type="file" ref={ inputRef } onChange={ onSelectFile } style={ { display: 'none' } } />
        <LoadingButton
          color="primary"
          variant="outlined"
          component="span"
          size="small"
        >
          Обзор
        </LoadingButton>
      </label>
      { typeof input.value === 'object' ?
        <div style={{ marginLeft: '16px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span style={ { color: 'rgba(0, 0, 0, 0.6)', fontSize: '16px', marginRight: '12px' } }>
            { input.value.fileName }
          </span>
          <ClearIcon sx={ { cursor: 'pointer', color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' } } onClick={ onClearFile } />
        </div>
        :
        <></>
      }
    </>
  )
}
