/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CardMethod } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  nmid: number,
  query: {
  /** ID кабинета */
    companymarketplace_id: number,
  /** Метод */
    method: CardMethod,

}
}>

export const apiFunction = ({ nmid,query }: IRequest) =>
  api.patch<ResponseType>(`/wbproxy/basket/card/${nmid}`, {}, { params: query}).then(res => res.data)


export const useOperation = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

