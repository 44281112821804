/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { CharacteristicList } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<CharacteristicList>

export type IRequest = CamelCasedPropertiesDeep<{
  query: {
  /**
   * Subject Id
   * ID Категории subject_id
   */
    subject_id: number,
  /**
   * Companymarketplace Id
   * Идентификатор кабинета
   */
    companymarketplace_id: number,
  /**
   * Timeout
   * Гарантированное время отклика в миллисекундах
   * @default 5000
   */
    timeout?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.get<ResponseType>(`/wbproxy/attributes`,{ params: query}).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ query }: IRequest) => ['get', `/wbproxy/attributes`, { query }] as const

export const usegetAttributes = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

