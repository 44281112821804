import { CellType, ColumnConfig } from 'shared/ui/components/Table/model/types'
import { SearchResultTypeCamel } from 'shared/api/products/models'

type ColumnName =
  | keyof Omit<
      SearchResultTypeCamel,
      'productId' | 'marketplaceUrl' | 'columnNames' | 'cabinetName'
    >
  | 'photo'
type ColumnNames = Record<ColumnName, ColumnName>

export const columns: ColumnNames = {
  goodsType: 'goodsType',
  size: 'size',
  ruSize: 'ruSize',
  tags: 'tags',
  goodsId: 'goodsId',
  orderGrowthFactor: 'orderGrowthFactor',
  newProduct: 'newProduct',
  days30Orders: 'days30Orders',
  days30OrdersItems: 'days30OrdersItems',
  days30SalesItems: 'days30SalesItems',
  days30Sales: 'days30Sales',
  inSupply: 'inSupply',
  marketplaceId: 'marketplaceId',
  xyzClass: 'xyzClass',
  abcClass: 'abcClass',
  photo: 'photo',
  hasPhoto: 'hasPhoto',
  name: 'name',
  marketplaceName: 'marketplaceName',
  marketplaceCategory: 'marketplaceCategory',
  brand: 'brand',
  autoOrdering: 'autoOrdering',
  marketplaceCode: 'marketplaceCode',
  supplierCode: 'supplierCode',
  barcodes: 'barcodes',
  stocks: 'stocks',
  suppliers: 'suppliers',
  price: 'price',
  companyName: 'companyName',
  cardStatus: 'cardStatus',
  syncStatus: 'syncStatus',
  inArchive: 'inArchive',
  stocksDate: 'stocksDate',
} as const

interface ColumnsConfigProps {
  path?: string
  config: Array<ColumnConfig<ColumnName>>
  isEditable?: boolean
  onCommitChanges?: () => void
}

export const columnsConfig: ColumnsConfigProps = {
  config: [
    {
      name: columns.abcClass,
      columnName: columns.abcClass,
      title: 'ABC',
      width: 70,
      sortingEnabled: false,
      cellType: CellType.ProductAbcClass,
    },
    {
      name: columns.xyzClass,
      columnName: columns.xyzClass,
      title: 'XYZ',
      width: 70,
      sortingEnabled: false,
      cellType: CellType.ProductXyzClass,
    },
    {
      name: columns.photo,
      columnName: columns.photo,
      title: 'Фото',
      width: 86,
      sortingEnabled: false,
      align: 'center',
      cellType: CellType.Photo,
    },
    {
      name: columns.name,
      columnName: columns.name,
      title: 'Наименование',
      width: 175,
      sortingEnabled: true,
      cellType: CellType.NameLink,
    },
    {
      name: columns.marketplaceName,
      columnName: columns.marketplaceName,
      title: 'Маркетплейс',
      width: 178,
      sortingEnabled: true,
    },
    {
      name: columns.autoOrdering,
      columnName: columns.autoOrdering,
      title: 'Автозаказ',
      width: 150,
      sortingEnabled: true,
      cellType: CellType.AutoOrdering,
    },
    {
      name: columns.marketplaceCategory,
      columnName: columns.marketplaceCategory,
      title: 'Категория',
      width: 150,
      sortingEnabled: true,
    },
    {
      name: columns.brand,
      columnName: columns.brand,
      title: 'Бренд',
      width: 170,
      sortingEnabled: true,
    },
    {
      name: columns.marketplaceCode,
      columnName: columns.marketplaceCode,
      title: 'Номенклатура маркетплейса',
      width: 150,
      sortingEnabled: true,
      wordWrapEnabled: true,
      cellType: CellType.MarketplaceCode,
    },
    {
      name: columns.supplierCode,
      columnName: columns.supplierCode,
      title: 'Артикул продавца',
      width: 240,
      sortingEnabled: true,
      wordWrapEnabled: true,
    },
    {
      name: columns.size,
      columnName: columns.size,
      title: 'Размер\nРос. размер',
      width: 115,
      wordWrapEnabled: true,
      sortingEnabled: false,
      cellType: CellType.ProductSize
    },
    {
      name: columns.barcodes,
      columnName: columns.barcodes,
      title: 'Штрих-код',
      width: 172,
      sortingEnabled: false,
      cellType: CellType.Barcodes,
    },
    {
      name: columns.stocks,
      columnName: columns.stocks,
      title: 'Остатки',
      width: 108,
      sortingEnabled: true,
    },
    {
      name: columns.price,
      columnName: columns.price,
      title: 'Цена, р.',
      width: 108,
      sortingEnabled: true,
    },
    {
      name: columns.companyName,
      columnName: columns.companyName,
      title: 'Продавец',
      width: 245,
      sortingEnabled: true,
    },
    {
      name: columns.suppliers,
      columnName: columns.suppliers,
      title: 'Поставщик',
      width: 245,
      sortingEnabled: true,
    },
    {
      name: columns.cardStatus,
      columnName: columns.cardStatus,
      title: 'Статус продажи на маркетплейсе',
      width: 216,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.CardStatus,
    },
    {
      name: columns.syncStatus,
      columnName: columns.syncStatus,
      title: 'Статус синхронизации',
      width: 213,
      sortingEnabled: false,
      wordWrapEnabled: true,
      cellType: CellType.SyncStatus,
    },
  ],
}
