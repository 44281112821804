/* eslint-disable */
import { api } from 'shared/api/base'
import { useQuery } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'
import { ReviewTemplate } from '../Api'

export type ResponseType = CamelCasedPropertiesDeep<ReviewTemplate>

export type IRequest = CamelCasedPropertiesDeep<{
  templateId: number
}>

export const apiFunction = ({ templateId }: IRequest) =>
  api.get<ResponseType>(`/review/models/templates/${templateId}`).then(res => res.data)


type QueryKey = ReturnType< typeof getQueryKey >
export const getQueryKey = ({ templateId }: IRequest) => ['get', `/review/models/templates/${templateId}`, { templateId }] as const

export const useGetReviewTemplate = (queryParams: IRequest, options?: any) =>
  useQuery<
    ResponseType,
    errorType,
    ResponseType,
    QueryKey
  >(
    getQueryKey(queryParams),
    (context) => apiFunction(context.queryKey[2]),
    { staleTime: Infinity, keepPreviousData: true, refetchOnMount: false, ...options }
  )

