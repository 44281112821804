/* eslint-disable */
import { api } from 'shared/api/base'
import { useMutation } from 'react-query'
import { CamelCasedPropertiesDeep } from 'type-fest'


export type ResponseType = CamelCasedPropertiesDeep<any>

export type IRequest = CamelCasedPropertiesDeep<{
  query?: {
  /**
   * Customer Code
   * customer_code
   */
    customer_code?: string,
  /**
   * Company Id
   * company_id
   */
    company_id?: number,

}
}>

export const apiFunction = ({ query }: IRequest) =>
  api.patch<ResponseType>(`/wbproxy/token/check`, {}, { params: query}).then(res => res.data)


export const usecheckToken = () =>
  useMutation<
    ResponseType,
    errorType,
		IRequest
  >(apiFunction)

